
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#mapContainer {
  height: 100%;
  width: 100vw;
}

.leaflet-touch .leaflet-bar {
  border: 0;
  background-clip: padding-box;
}

.MuiDrawer-paper {
  width: 40%;
  align-items: center;
  overflow-y: hidden;
}

.MuiBox-root {
  margin: 0
}

@media only screen and (min-width: 320px) and (max-width: 500px){
  .MuiDrawer-paper {
      width: 90%;
  }
}

@media only screen and (max-width: 500px){
  .MuiFab-root {
    margin-bottom: 15vh;
  }
}

@media only screen and (min-width: 501px) {
  .MuiFab-root {
    margin-bottom: 8vh;
  }
}


@media only screen and (min-width: 501px) and (max-width: 1023px){
  .MuiDrawer-paper {
    width: 60%;
  }
}

@media only screen and (min-width: 1024px){
  .MuiDrawer-paper {
    width: 40%;
  }
}

@media (hover: none) {
  .leaflet-tooltip-pane {
    display: none
  }
}

.MuiAutocomplete-inputRoot {
  height: 3rem
}